import TYPES from '@/types';

// Application
import GetGoalDefinitionStepQuery
  from '@/modules/flagship/goal-definition-step/application/queries/get-goal-definition-step-query';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class GoalsUndefinedViewModel {
  @Inject(TYPES.GET_GOAL_DEFINITION_STEP_QUERY)
  private readonly get_goal_definition_step_query!: GetGoalDefinitionStepQuery;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.kuspit-dashboard.goals.goals-undefined-card';

  show_flagship_goals_modal = false;

  show_flagship_goals_wizard_modal = false;

  show_all_flagship_goals_modal = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    await this.loadGoalDefinitionStep();
  }

  showAllFlagshipGoalsModal = async () => {
    await this.loadGoalDefinitionStep();
    this.show_all_flagship_goals_modal = true;
  }

  showDefineGoalsWizard = () => {
    this.show_flagship_goals_wizard_modal = true;
    this.show_flagship_goals_modal = false;
  }

  closeDefineGoalsModal = () => {
    this.show_flagship_goals_wizard_modal = true;
    this.show_flagship_goals_modal = false;
  }

  loadGoalDefinitionStep = async () => {
    try {
      const { main_item } = await this.get_goal_definition_step_query.execute(false);

      this.show_flagship_goals_wizard_modal = main_item !== 'informative' && main_item !== undefined;
      this.show_flagship_goals_modal = main_item === 'informative' || main_item === undefined;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_goal_definition_step'));
    }
  };
}
